import { useState } from 'react';
import striptags from 'striptags';
import { InnerHtml, Button } from '@geberit/gdds';

// styles
import styles from './dlc-cart-orders.module.scss';

// types
import type { CartOrdersPropsGroup } from '../types';

// components
import { Translation } from 'components/Translation/Translation';
import { FormController } from 'components/ContentElements/Form/form-controller';

// utils
import { decodingContent } from 'utils/decodingContent';
import { range } from 'utils/range';

function CartOrders({
  type,
  orders,
  updateAmount,
  updateOrder,
  form,
  getSessionStorage,
  onFinished,
  downloadAsZip,
}: Readonly<CartOrdersPropsGroup>) {
  const [isSuccess, setIsSuccess] = useState(false);

  const onInputChange = (e, order) => {
    const {
      currentTarget: { value },
    } = e;
    const amount = value !== '' ? range(Number(value), 100) : undefined;

    updateAmount({
      ...order,
      amount,
    });
  };

  const onInputBlur = (order) => {
    if (!order.amount) {
      updateAmount({
        ...order,
        amount: 1,
      });
    }
  };

  const renderTable = () => {
    const tableData = orders.map((order) => (
      <tr key={order.id}>
        <td>
          <InnerHtml as="span" content={decodingContent(order.title)} />
        </td>
        <td>
          {type === 'order' ? (
            <input
              type="number"
              value={order.amount}
              onChange={(e) => onInputChange(e, order)}
              onBlur={() => onInputBlur(order)}
            />
          ) : (
            String(decodingContent(order?.meta?.fileExtension)).toUpperCase()
          )}
        </td>
        <td>
          <Button
            stylingType="flatHighlight"
            symbol="Trash"
            iconAlignment="left"
            onClick={() => {
              updateOrder(order, -1);
            }}
          >
            <Translation id="web20_downloadcenter_delete" />
          </Button>
        </td>
      </tr>
    ));

    return (
      <>
        {type === 'order' && (
          <div className="cell medium-12 c-cart-orders__headline">
            <h3 className="h4">
              <Translation id="web20_downloadcenter_your_order" />
            </h3>
          </div>
        )}
        <table className="c-cart-orders__table c-cart-orders__table--group">
          <tbody>{tableData}</tbody>
          <thead>
            <tr>
              <th>
                <Translation id="web20_downloadcenter_cartmodal_cell_designation" />
              </th>
              <th>
                <Translation
                  id={
                    type === 'order'
                      ? 'web20_downloadcenter_cartmodal_cell_amount'
                      : 'web20_downloadcenter_cartmodal_cell_format'
                  }
                />
              </th>
              <th />
            </tr>
          </thead>
        </table>
      </>
    );
  };
  const orderInformation = orders
    .map((order) => `${order.amount}x ${striptags(order.title)}`)
    .join('\r\n');

  const onSuccess = () => {
    orders.forEach((order) => updateOrder(order, -1));
    setIsSuccess(true);
    if (typeof onFinished === 'function') onFinished();
  };

  return (
    <div className={`c-downloadorder ${styles.orderWrapper}`}>
      {!isSuccess && renderTable()}
      {!isSuccess && type === 'order' && (
        <div className="cell medium-12 c-cart-orders__headline">
          <h3 className="h4">
            <Translation id="web20_downloadcenter_your_data" />
          </h3>
        </div>
      )}
      {type === 'order' && (
        <FormController
          {...form}
          formType="downloadCenter"
          orderInformation={orderInformation}
          getSessionStorage={getSessionStorage}
          afterSubmit={onSuccess}
          isModal
        />
      )}
      {type === 'download' && (
        <Button
          stylingType="primary"
          symbol="Download"
          iconAlignment="left"
          onClick={() => downloadAsZip()}
        >
          <Translation id="web20_downloadcenter_cartdownload_as_zip" />
        </Button>
      )}
    </div>
  );
}

export default CartOrders;
