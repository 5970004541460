import { bool } from 'config/env';
import { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers';
import { Log } from 'services/log';

export function getBaseUrl(headers: ReadonlyHeaders) {
  return (headers.get('x-base-url') || process.env.BASE_URL) ?? '';
}

function getHasBasicAuth(headers: ReadonlyHeaders) {
  return bool(headers.get('x-has-basic-auth') ?? process.env.HAS_BASIC_AUTH);
}

export function getBasicAuth(headers: ReadonlyHeaders) {
  return (
    headers.get('x-authorization') ??
    (getHasBasicAuth(headers) && process.env.BASIC_AUTH ? process.env.BASIC_AUTH : undefined)
  );
}

export function getIsPreview(headers: ReadonlyHeaders) {
  return bool(headers.get('x-is-preview') || process.env.IS_PREVIEW);
}

export function getAppStage() {
  return process.env.APP_STAGE ?? 'prod';
}

export function getTheme(headers: ReadonlyHeaders) {
  return headers.get('x-theme-name') || process.env.THEME || 'DEFAULT';
}

export function getNewsDeskKey(headers: ReadonlyHeaders) {
  return (
    headers.get('x-news-desk-key') ||
    process.env.NEWS_DESK_KEY ||
    '5ad010fdadebfe60a6c0830f3c1a1e5c'
  );
}

function getCaasProjectDb(headers: ReadonlyHeaders) {
  return (headers.get('x-caas-project-db') || process.env.CAAS_PROJECT_DB) ?? '';
}

function getIsPrelive(headers: ReadonlyHeaders) {
  return bool(headers.get('x-is-prelive') || process.env.IS_PRELIVE);
}

export function getServerVars(headers: ReadonlyHeaders): ServerData {
  return {
    logLevel: process.env.LOGLEVEL_FRONTEND,
    userAgent: headers.get('user-agent') ?? '',
    isPreview: getIsPreview(headers),
    isPrelive: getIsPrelive(headers),
    cdnUrl: '',
    gtmUrl: process.env.GTM_URL ?? '',
    baseUrl: getBaseUrl(headers),
    basicAuth: getBasicAuth(headers) ?? false,
    hasBasicAuth: getHasBasicAuth(headers),
    nodeEnv: 'production',
    port: '3000',
    theme: getTheme(headers),
    caasProjectDb: getCaasProjectDb(headers),
    isHeaderSources: true,
    appStage: getAppStage(),
    campus: getCampusEnv(headers),
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY ?? '',
  };
}

export function getCampusEnv(headersList: ReadonlyHeaders) {
  const catalogHeader = headersList?.get('x-campus-catalog-id');

  // if the x-campus-catalog-id header is not set, return an empty object,
  // so no campus data is available in nextjs data displayed on the website
  if (!catalogHeader && getAppStage() === 'prod') {
    return {
      CAMPUS_CATALOG_ID: '',
      CAMPUS_DASHBOARD_REPORT: '',
      CAMPUS_CLASS_CAMPUS: '',
      CAMPUS_CLASS_COURSETYPES: '',
      CAMPUS_CLASS_TARGETGROUPS: '',
      CAMPUS_CLASS_COMP_KNOWLEDGE: '',
      CAMPUS_CLASS_LEARNINGFORMS: '',
      CAMPUS_CLASS_LOCATIONS: '',
      CAMPUS_BASE_URL: '',
      CAMPUS_COSTS_INCLUDED: '',
      CAMPUS_META: {
        NAME: '',
        SUMMARY: '',
        PLANNING_STATUS: '',
        DURATION_OF_USE: '',
        CATERING: '',
        DESCRIPTION: '',
        HOTEL: '',
        ENTERTAINMENT: '',
        INFOBOX: '',
        START_DATE: '',
        END_DATE: '',
        DURATION: '',
        LOCATION: '',
        MAX_CAPACITY: '',
        PRICE: '',
        IMAGE: '',
        LANGUAGE: '',
        COURSE_CONTENTS: [],
        COURSE_BENEFITS: [],
        WEBINAR: '',
        ELEARNING: '',
        WEBCAST: '',
        EVENT: '',
        SEMINAR: '',
        PODCAST: '',
        POPULAR: '',
        TARGETGROUP: '',
      },
      CAMPUS_FILTER_TYPE_BLACKLIST: [],
      SCORM_URI: '',
    };
  }

  // use the campus catalog id from the header if it is set otherwise use the env variable
  // env variable is used for local development
  let campusCatalogId = String(catalogHeader || process.env.CAMPUS_CATALOG_ID!);
  if (!/^\d+\/\d+\/\d+\/\d+$/.test(campusCatalogId)) {
    Log.warn(
      String.raw`campus catalog id must be according to the scheme "/^\d+\/\d+\/\d+\/\d+$/"`,
    );
  }
  if (!campusCatalogId.endsWith('/')) {
    campusCatalogId = campusCatalogId.concat('/');
  }

  return {
    CAMPUS_CATALOG_ID: campusCatalogId,
    CAMPUS_DASHBOARD_REPORT: process.env.CAMPUS_DASHBOARD_REPORT!,
    CAMPUS_CLASS_CAMPUS: process.env.CAMPUS_CLASS_CAMPUS!,
    CAMPUS_CLASS_COURSETYPES: process.env.CAMPUS_CLASS_COURSETYPES!,
    CAMPUS_CLASS_TARGETGROUPS: process.env.CAMPUS_CLASS_TARGETGROUPS!,
    CAMPUS_CLASS_COMP_KNOWLEDGE: process.env.CAMPUS_CLASS_COMP_KNOWLEDGE!,
    CAMPUS_CLASS_LEARNINGFORMS: process.env.CAMPUS_CLASS_LEARNINGFORMS!,
    CAMPUS_CLASS_LOCATIONS:
      headersList.get('x-campus-class-locations') || process.env.CAMPUS_CLASS_LOCATIONS!,
    CAMPUS_BASE_URL: process.env.CAMPUS_BASE_URL!,
    CAMPUS_COSTS_INCLUDED: process.env.CAMPUS_COSTS_INCLUDED!,
    CAMPUS_META: {
      NAME: '10000',
      SUMMARY: '1104957',
      PLANNING_STATUS: '10072',
      DURATION_OF_USE: '10068',
      CATERING: '1007853',
      DESCRIPTION: '10001',
      HOTEL: '1007609',
      ENTERTAINMENT: '1007855',
      INFOBOX: '1105028',
      START_DATE: '10004',
      END_DATE: '10005',
      DURATION: '10018',
      LOCATION: '10097',
      MAX_CAPACITY: '10009',
      PRICE: '10033',
      IMAGE: '10012',
      LANGUAGE: '1139040',
      COURSE_CONTENTS: ['1139073', '1139075', '1139077', '1139079', '1139081', '1139083'],
      COURSE_BENEFITS: ['1139085', '1139087', '1139089', '1139091', '1139095', '1139097'],
      WEBINAR: '1140388',
      ELEARNING: '1140387',
      WEBCAST: '1140389',
      EVENT: '1140386',
      SEMINAR: '1140386',
      PODCAST: '896371',
      POPULAR: '1140353',
      TARGETGROUP: '1140295',
    },
    CAMPUS_FILTER_TYPE_BLACKLIST: [1140386, 1140388],
    SCORM_URI: process.env.APP_STAGE?.includes('prod')
      ? 'https://campus.prod.web.geberit.com/pages/direct-login.jsf'
      : 'https://campus.qa.web.geberit.com/pages/direct-login.jsf',
  };
}
